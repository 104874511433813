<template>
    <div class="card" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table custom-table-padding">
                <thead>
                    <tr>
                        <th>PI_Number</th>
                        <th>PI_Date</th>
                        <th>Customer_Name</th>
                        <th>Buyer_Name</th>
                        <th>Total_PI_Qty</th>
                        <th>Total_PI_Value</th>
                        <th>Currency</th>
                        <th>Delivered_Qty</th>
                        <th>Sales_Person</th>
                        <th>Quotation_Ref&Date</th>
                        <th>Pending_Delivery_Qty</th>
                        <th>L/C_Number(s)</th>
                        <th>L/C_Received_Amount</th>
                        <th>Due_Amount</th>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in purchase" :key="i">
                    <td> <a @click="openGeneralModal(item)" href="javascript:void(0)">{{ item.bill_number }}</a></td>
                    <td> {{ item.date }}</td>
                    <td> {{ item.party_name }}</td>
                    <td> {{ item.buyer_name }}</td>
                    <td> {{ item.total_qty }}</td>
                    <td> {{ item.bill_amount }}</td>
                    <td> {{ item.currency }}</td>
                    <td> {{ item.delivered_qty }}</td>
                    <td> - </td>
                    <td> {{ item.order_number }}</td>
                    <td> {{ parseFloat(item.total_qty) - parseFloat(item.delivered_qty) }}</td>
                    <td> - </td>
                    <td> 0 </td>
                    <td> {{ item.due_amount }}</td>
                </tr>

                </tbody>
            </table>
            <p v-if="!purchase.length" class="text-center mt-5">No data</p>
        </div>

        <OrderGeneralModal 
            v-if="store.state.isModalOpen"
            :saleMaster="singleSaleMaster"
        />
    </div>
</template>

<script>
import figureFormatter from '@/services/utils/figureFormatter';
import handlePurchase from "@/services/modules/purchase";
import piPdfPrint from '@/services/utils/piPdfPrint';
import handleCompany from "@/services/modules/company";
import handleBarcode from "@/services/modules/barcode";
import { useStore } from "vuex";
import { formatDate } from '@/services/utils/global';
import handleHospital from '@/services/modules/hospital'
import OrderGeneralModal from '@/components/molecule/company/inventory/order-management/OrderGeneralModal.vue';

export default {  
    name: 'PurchaseListTable',
    data() {
        return{
            companyInfo: {},
            invoice: {},
            barcodeQuery: '',
            barcode: '',
            user: {},
            qrcode: '',
            invoiceNotes: [],
            singleSalesOrder: {},
            singleSaleMaster: []
        }
    },
    props: {
        purchase: Array,
        route_name: {
            type: String,
            default: 'purchase-bill-edit'
        },
        fromApproval: {
            type: Boolean,
            default: false
        }
    },

    components: {
        OrderGeneralModal
    },

    setup() {
        const {commaFormat} = figureFormatter()
        const { fetchSalesBill, fetchSalesOrderById } = handlePurchase();
        const {fetchCompanyInfo} = handleCompany();
        const { exportToPDF } = piPdfPrint();
        const { fetchBarcode } = handleBarcode();
        const store = useStore();
        const { notesByType } = handleHospital()

        return {
            commaFormat,
            fetchSalesBill,
            fetchSalesOrderById,
            exportToPDF,
            fetchCompanyInfo,
            fetchBarcode,
            store,
            notesByType
        }
    },
    mounted() {
         this.fetchCompanyInfo(this.$route.params.companyId).then( (res) => {
            this.companyInfo = res.data;
         }).catch((error) => {
         });

         this.getInvoiceNotes();

         this.user = this.store.state.user.user
    },
    methods: {
        openGeneralModal (item) {
            this.singleSaleMaster = item;
            this.store.state.isModalOpen = true;
        },
        async getInvoiceNotes () {
            const companyQuery = `?company_id=${this.$route.params.companyId}&show_default_only=1`;
            await this.notesByType(companyQuery, "invoice_terms").then((res) => {
                if(! res.status) return;
                this.invoiceNotes = res.data.filter((item) => item.full_desc);
            })
        },
        async printPreInvoice(item) {
            const companyQuery = `?company_id=${this.$route.params.companyId}`;
            await this.fetchSalesOrderById(item.id, companyQuery).then(async (res) => {
                if(! res.data) return;

                this.singleSalesOrder = res.data

                let qrcodeData = JSON.stringify({
                    ref_no: this.singleSalesOrder.bill_number,
                    ref_date: this.singleSalesOrder.date,
                    created_by: this.singleSalesOrder.created_by_name,
                    created_date_time: formatDate(new Date())
                })

                this.barcodeQuery = `?code=${this.singleSalesOrder.bill_number}&qrcode=${qrcodeData}`;

                await this.fetchBarcode(this.barcodeQuery).then( (res) => {
                    if(res.status){
                        this.qrcode = res.qrcode
                        this.exportToPDF(this.companyInfo, this.singleSalesOrder, this.barcode, this.qrcode, this.user.name, 1, this.invoiceNotes)
                    }
                })
            })
            .catch( (err) => {
            })
        },
        async goToPrint(id) {
            const companyQuery = `?company_id=${this.$route.params.companyId}`;

            await this.fetchSalesBill(id, companyQuery).then( async (res) =>{

                    if(! res.status) return;
                    this.invoice = res.data;

                    let qrcodeData = JSON.stringify({
                        ref_no: this.invoice.bill_number,
                        ref_date: this.invoice.date,
                        created_by: this.invoice.created_by_name,
                        created_date_time: formatDate(new Date())
                    })

                    this.barcodeQuery = `?code=${this.invoice.bill_number}&qrcode=${qrcodeData}`;

                    await this.fetchBarcode(this.barcodeQuery).then( (res) => {
                        if(res.status){
                            this.barcode = res.barcode;
                            this.qrcode = res.qrcode
                            this.exportToPDF(this.companyInfo, this.invoice, this.barcode, this.qrcode, this.user.name, 1, this.invoiceNotes)
                        }
                    })

                }).catch( (err) => {
                })
        },
        goToInvoice(item) {
            this.$router.push({
                name: 'sales-invoice-add',
                params: this.$route.params,
                query: {
                    start: this.$route.query.start,
                    end: this.$route.query.end,
                    sales_order_id: item.id,
                    redirect_route: 'sales-order-approval'
                }
            })
        },
        goToEdit(id) {
            let dynamicKeyName = (this.route_name === 'purchase-bill-edit') ? 'purchaseId' : 'salesId'
            let param = {
              companyId: this.$route.params.companyId,
              moduleId: this.$route.params.moduleId,
              menuId: this.$route.params.menuId,
              pageId: this.$route.params.pageId,
            }
            param[dynamicKeyName] = id;

            const query = this.$route.query;
            if(this.$route.name === 'sales-order-approval'){
                query.type = 'approval';
            }

            this.$router.push({
                name: this.route_name,
                params: param,
                query: query
            })
        }
    }
}
</script>

<style scoped>
.btn-challan {
    background-color: #249024 !important;
}
.custom-table-padding {
    width: 180%
}
</style> 